import React from "react"
import { graphql } from "gatsby"
import Container from "../components/layout/Container"
import Header from "../components/Header"
import Space from "../components/Space"
import Content from "../components/layout/Content"
import Pagination from "../components/Pagination"
import FaqItem from "../components/FaqItem"
import { Helmet } from "react-helmet"
import { SEO_KEYWORDS, SEO_DESCRIPTION } from "../utils"
import Title from "../components/Title"
import TrainingBanner from "../components/TrainingBanner"

export default props => {
    const { edges } = props.data.allMarkdownRemark
    const { pageContext } = props
    return (
        <Container>
            <Helmet>
                <title>Вопросы и ответы на вплетайся</title>
                <meta name="keywords" content={SEO_KEYWORDS} />
                <meta name="description" content={SEO_DESCRIPTION} />
            </Helmet>
            <Header />
            <TrainingBanner/>
            <Space />
            <Content>
                <Title>Вопросы и ответы</Title>
                {edges.map((item, i) => (
                    <FaqItem
                        key={`faqs_${i}`}
                        title={
                            item.node.frontmatter.title
                                ? item.node.frontmatter.title
                                : "Нет заголовка"
                        }
                        date={
                            item.node.frontmatter.date
                                ? item.node.frontmatter.date
                                : ""
                        }
                        slug={
                            item.node.frontmatter.slug
                                ? item.node.frontmatter.slug
                                : ""
                        }
                    />
                ))}
            </Content>
            <Pagination
                currentPage={pageContext.currentPage}
                totalItems={pageContext.totalItems}
                display={pageContext.limit}
                urlFirst="/faq"
                url="/faq/index/page/"
            />
            <Space />
        </Container>
    )
}

export const faqListQuery = graphql`
    query faqsListQuery($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
            filter: { frontmatter: { type: { eq: "faq" } } }
        ) {
            edges {
                node {
                    frontmatter {
                        slug
                        title
                        date
                    }
                }
            }
        }
    }
`
