import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const FaqItem = styled.div`
    margin-bottom: 4rem;
`

const FaqDate = styled.div``
export default ({ title = "", slug = "", date = "" }) => {
    let dateFormated = "";
    if (date) {
        const dt = new Date(date);
        dateFormated = `${dt.getDate()}.${dt.getMonth() + 1}.${dt.getFullYear()}`
    }
    return (
        <FaqItem>
            <Link
                to={slug}
                css={css`
                    display: inline-box;
                    color: #377c9e;
                    font-size: 2rem;
                    margin-bottom: 1rem;
                    &:hover {
                        color: black;
                    }
                `}
            >
                {title}
            </Link>
            <FaqDate>{dateFormated}</FaqDate>
        </FaqItem>
    )
}
